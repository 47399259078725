<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Detail Kategori Pengumuman'">
          <template v-slot:body>
            <div class="row align-items-center">

              <!-- Profile Image -->
              <div class="col-md-4">
                <div class="d-flex justify-content-center align-items-center image-container">
                  <div style='height: 350px; width: 350px; position: relative'>
                    <img
                      class="image"
                      :src="data.image"
                      @load="onImageLoad()"
                    >
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div
                class="col"
                v-if="imgLoaded"
              >
                <table class="table mb-3">
                  <tr>
                    <td style="width: 25%"><strong>Judul</strong></td>
                    <td>{{ data.title }}</td>
                  </tr>
                  <tr>
                    <td><strong>Kategori Pengumuman</strong></td>
                    <td>{{ data.announcement_category_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Konten</strong></td>
                    <td><div v-html="data.content"></div></td>
                  </tr>
                </table>

                <button
                  @click="$router.push({path: '/announcements/edit/' + data.id})"
                  class="btn mx-1 btn-success"
                  v-if="btn"
                ><i class="far fa-fw fa-edit"></i> Edit</button>
                <button
                  @click="deleteData"
                  class="btn mx-1 btn-danger"
                  v-if="btn"
                ><i class="far fa-fw fa-trash-alt"></i> Hapus</button>
              </div>

            </div>
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      // Data
      data: {},
      // Other
      imgLoaded: true,
      // User Access
      btn: false,
    };
  },

  components: {
    Card,
  },

  methods: {
    onImageLoad() {
      this.imgLoaded = true;
    },

    async deleteData() {
      let result = await module.delete(
        "announcements/" + this.$route.params.id
      );
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push("/announcements/list");
      }
    },

    async get() {
      this.data = await module.get("announcements/" + this.$route.params.id);
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("/announcements/list");
      }
    },

    setActiveButton(){
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for(a = 0; a < access_right.length; a++){
        if(access_right[a] == "2302"){
          this.btn = true
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Pengumuman", route: "/announcements/list" },
      { title: "Detail Pengumuman" },
    ]);
    // Get Data
    this.get();
    this.setActiveButton()
  },
};
</script>